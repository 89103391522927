import Footer from "./Layout/Footer"
import Header from "./Layout/Header"
import * as React from "react"
import { navigate } from "gatsby"
import { isBrowser, isLoggedIn } from "../utils/auth"

function Layout({ children }) {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  )
}

export default Layout
